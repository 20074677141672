<template>
    <div class="d-flex gap-2">
      <!-- Country Code Section -->
      <div class="input_num_code">
        <div>
          <p class="fs_12 mb-0">Code</p>
          <img v-if="selectedItem?.flag?.image" :src="selectedItem?.flag?.image" alt="Country Flag">
        </div>
        <div class="">
          <select class="styled-select txt_blue" id="countryCode" v-model="selectedItem" @change="broadcastNewValue">
            <option class="option-style" v-for="country in countries" :value="country" :key="country.code">
              {{ country?.phone_code?.dial_code }} {{ country?.name }}
            </option>
          </select>
        </div>
      </div>
  
      <!-- Phone Number Input -->
      <input type="number" class="text-start" placeholder="Phone" v-model="phone_number" @blur="broadcastNewValue">
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        countries: [],
        filteredList: [],
        selectedItem: null,
        phone_number: null,
        phone_number_code: null,
      };
    },
    props: {
      current_phone_number: null,
      default_country: null,
    },
    computed: {
    selectedDialCode() {
      return this.selectedItem ? this.selectedItem.phone_code.dial_code : '';
    },
  },
    methods: {
      broadcastNewValue() {
        this.$emit('phone_number_updated', this.phone_number_code + this.phone_number);
        this.$emit('country_code_updated', this.phone_number_code);
      },
    },
    async mounted() {
      try {
        let response = await axios.post('/getCountries');
        this.countries = response.data;
        this.filteredList = [...this.countries];
  
        let default_country = this.filteredList.find(
          (c) => c.code === this.default_country.code
        );
  
        this.selectedItem = default_country || this.filteredList[0];
        this.phone_number_code = this.selectedItem?.phone_code?.dial_code;
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    },
  };
  </script>
  
  <style scoped>
  /* Add your scoped styles here */


.styled-select {
  width: 100%;
  padding: 5px;
  font-size: 14px;
  background-color: #f1f1f1;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.styled-select:focus {
  border-color: #fe237c;
  outline: none;

}

.option-style {
  font-size: 14px;
  padding: 10px;
}
  </style>
  