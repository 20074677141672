<template>

    <div v-if="current_view_style == 3" class="flight_tab_content pt-3 pb-1">
        <div class="booking_modal_after" v-on:click.prevent="remove_modal()"></div>
    
        <div class="box_1">
                            <div class="bg_silver rounded_10 position-relative">
                                <label class="w-100">
                                    <span class="d-block fs_14">Find accommodation in</span>
                                    <input @focus="activateAutocomplete(1)" autocomplete="off" v-model="search_keyword" type="text" id="inp_hotel" :class="{ 'is-invalid': submitted && search_keyword == '' }" placeholder="Where do you want to go?">
                                    <div v-if="submitted && search_keyword == ''" class="invalid-feedback">
                            Location is required
                        </div>
                        <span class="clear_input_ticket" @click="clearLocationInput()">
                            <img src="/img/clear_cross.svg" alt="Clear icon">
                        </span>
                                    <AutocompleteComponent ref="autocomplete_1" :has_initial_value="initial_autocomplete_value"
                                    @autocomplete_result_selected="select_result" :type="'activities'" :keyword="search_keyword">
                                </AutocompleteComponent>
                                </label>
                            </div>
                        </div>
                        <div class="box_2">
                            <div class="bg_silver rounded_10 d-flex position-relative">
                                <label for="date_range" v-on:click.prevent="show_modal('#date_range')">
                                    <span class="d-block fs_14">Check-in</span>
                                    <input autocomplete="off" :value="first_date" type="button" id="first_date" class="left-aligned-text drp-selected-start" readonly="">
                                </label>
                                <span class="line"></span>
                                <label for="date_range" v-on:click.prevent="show_modal('#date_range')">
                                    <span class="d-block fs_14">Check-out</span>
                                    <input autocomplete="off" :value="second_date" type="button" id="second_date" class="left-aligned-text drp-selected-end" readonly="">
                                </label>
                                <input type="text" class="date_range">
                            </div>
                            <div id="date_range" :class="{'booking_modal': !isMobile}"  style="position:absolute;width: max-content; left: 350px;z-index:100">

                            <DatePicker :unique_id="unique_id" @first_date_changed="handle_first_date"
            @second_date_changed="handle_second_date" ref="date_picker1" :range="'true'">
        </DatePicker>
                        </div>
                    </div>
                    <div class="box_3">
                      <div class="bg_silver rounded_10 d-flex position-relative"
                    v-on:click.prevent="show_modal('#booking_modal_2')">
                        <label>
                            <span class="d-block fs_14">Passengers</span>
                            <input type="button" class="left-aligned-text c_pointer" readonly ref="input_box1" :value="passengers_to_display" @focus="toggle_passengers_panel" />
                        </label>
                        <div id="booking_modal_2" class="booking_modal">
                            <div class="booking_modal_sm position-absolute w-100 h-100 bg-red" v-on:click.prevent="remove_modal()"></div>
                            <div class="shadow booking_box">
                                <div v-for="passenger in flight_passengers" class="d-flex justify-content-between mb-3">
                                    <div class="me-3">
                                        <p class="mb-0 fw_500">{{ passenger.passenger_type }}</p>
                                        <p class="mb-0 fs_12">{{ passenger.passenger_age_range }}</p>
                                    </div>
                                    <div class="wrap_inc_dec">
                                        <button class="decrement" v-on:click.prevent="removePassenger(passenger.id)">-</button>
                                        <input type="number" readonly class="numberInput" :value="passenger.passengers.length" />
                                        <button class="increment" v-on:click.prevent="addPassenger(passenger)">+</button>
                                    </div>
                                </div>

                                <button v-on:click.prevent="remove_modal()" class="ms-0 mt-2 txt_pink fw-bold btn_reset fs_14 c_pointer1">
                                                Apply
                                            </button>
                            </div>
                        </div>
                    </div>
                    </div>
                    <button class="d-flex align-items-center justify-content-center" @click="search" type="submit">
                      <img width="24" src="/img/search-icon.svg" alt="" />
                      <span class="d-md-none d-inline-block text-white fw-semibold fs_14">Find</span>
                  </button>
                  </div>
    </template>
    
    
    <script>
    import DatePicker from "./DatePicker-new.vue";
    import AutocompleteComponent from "./AutocompleteComponent.vue";
    export default {
        components: { AutocompleteComponent, DatePicker },
        data: function () {
            return {
                selected_result_object: null,
                submitted: false,
                city: '',
            city_id: '',
            city_name: '',
            country: [],
            search_keyword: '',
                passengers_to_display: '',
                isMobile: false,
                selected_language: '',
                invalid_return_date: false,
                holidayDatepickerVisible: false,
                unique_id: "activities_search",
                first_date: "",
                second_date: "",
                departure_search_keyword: "",
                destination_search_keyword: "",
                selected_departure_result_object: {},
                selected_destination_result_object: {},
                my_nice_selects: [],
                initial_autocomplete_value: false,
                initial_autocomplete_value2: false,
                flights_loading: true,
                selected_departure_result_object_valid: true,
                selected_destination_result_object_valid: true,
                first_date_valid: true,
                second_date_valid: true,
    
    
                passenger_panel_visible: false,
    
                flight_passengers: [
                    {
                        id: 1,
                        passenger_type: "Adult",
                        passenger_age_range: "18+",
                        passengers: [],
                        increments: 0,
                    },
                    /*         {
                                id: 2,
                                passenger_type: 'Student',
                                passenger_age_range: '18+',
                                passengers: [],
                                increments: 0,
                            },
                            {
                                id: 3,
                                passenger_type: 'Young People',
                                passenger_age_range: '12 - 17',
                                passengers: [],
                                increments: 0,
                            }, */
                    {
                        id: 4,
                        passenger_type: "Children",
                        passenger_age_range: "2 - 11",
                        passengers: [],
                        increments: 0,
                    },
                    /*     {
                            id: 5,
                            passenger_type: 'Small children in their own seat',
                            passenger_age_range: 'Under 2',
                            passengers: [],
                            increments: 0,
                        }, */
                    {
                        id: 6,
                        passenger_type: "Infant",
                        passenger_age_range: "Under 2",
                        passengers: [],
                        increments: 0,
                    },
                ],
    
                flight_class: [
                    {
                        id: 1,
                        class_name: "Economy",
                    },
                    /*          {
                                 id: 2,
                                 class_name: 'Premium Economy',
    
                             }, */
                    {
                        id: 3,
                        class_name: "Business",
                    },
                    {
                        id: 4,
                        class_name: "First Class",
                    },
                    /*        {
                               id: 5,
                               class_name: 'Several Class',
    
                           }, */
                ],
    
                flight_type: [
    
                    {
                        id: 1,
                        type_name: "One Way",
                    },
                    {
                        id: 2,
                        type_name: "Roundtrip",
                    } /* ,
                    {
                        id: 3,
                        type_name: 'Multiple Destinations',
    
                    } */,
                ],
    
                selected_flight_class: 1,
                selected_flight_type: 1,
            };
        },
        props: {
        has_initial_value: false,
        type: '',
        keyword: '',
            top: null,
            visible: false,
            current_view_style: null,
            search_data: null,
        },
        created() {
    this.checkIfMobile();
    window.addEventListener('resize', this.checkIfMobile); // Re-check on window resize
  },
    
        methods: {
            handle_first_date(event) {
            this.first_date = event
            //console.log('first_date ch:', this.first_date);
        },
        handle_second_date(event) {
            this.second_date = event
           // console.log('second_date ch:', this.second_date);

        },
            select_result: function (data) {
            this.search_keyword = data?.name;
            this.city_name = this.search_keyword
            this.city_id = data?.t_id
            this.selected_result_object = data;
            this.city = data?.name

        },
            passengers_breakup() {
                    if (!this.flight_passengers) {
                        return '';
                    }
                    let pax = this.flight_passengers;
                    let adults = 0;
                    let children = 0;
                    let infants = 0;

                    for (let i = 0; i < pax.length; i++) {
                        if (pax[i].id == 1) {
                            adults = pax[i].passengers.length;
                        }
                        if (pax[i].id == 4) {
                            children = pax[i].passengers.length;
                        }
                        if (pax[i].id == 6) {
                            infants = pax[i].passengers.length;
                        }
                    }
                    let total = adults + children + infants
                    let type_of_flight = ''
                    
                    let passengers = ''

                    if (adults > 0) {
                        passengers += adults + " Adult(s)"
                    }
                    if (children > 0) {
                        passengers += children + " Child,"
                    }
                    if (infants > 0) {
                        passengers += infants + ", Infant,"
                    }
                    this.passengers_to_display = `${passengers}`

                   // return `${type_of_flight}, ${passengers}`

                },
            checkIfMobile() {
      // You can adjust the width threshold according to your mobile definition
      this.isMobile = window.innerWidth <= 768;
    },
            show_modal: function(elem) {
      document.querySelector(elem).classList.add("active");
      document.querySelector('.booking_modal_after').classList.add("active");
    },
    
     remove_modal: function() {
      event.stopPropagation();
      let modal = document.querySelectorAll(".booking_modal");
      let after = document.querySelectorAll(".booking_modal_after");
      modal.forEach(element => {
        element.classList.remove("active");
      });
      after.forEach(after => {
        after.classList.remove("active");
      });
    },

            activateAutocomplete: function (autocomplete_index) {
                let autocomplete_name = 'autocomplete_' + autocomplete_index;
                this.$refs[autocomplete_name].activateAutocomplete();
    
    
            },
    
            clearLocationInput1: function () {
                this.departure_search_keyword = '';
                this.selected_departure_result_object = null;
            },
    
            clearLocationInput2: function () {
                this.destination_search_keyword = '';
                this.selected_destination_result_object = null;
            },
    
            hidePanel() {
                this.passenger_panel_visible = false;
            },

            search: async function (should_redirect = true) {
                let valid_input = true;
                let range = false;
                this.submitted = true;

                let d = new FormData();
    d.append('city_id', this.city_id)
    d.append('Name', this.city_name)
    d.append('City', this.city)
    d.append('check_in_date', this.first_date)
    d.append('check_out_date', this.second_date)
    d.append('paxRooms', JSON.stringify(this.flight_passengers));
    //d.append('nationalityCode', this.nationalityCode)

    try {
        let resp = await axios.post('/activities/prepare_search', d);
           // console.log(resp.data );

        let route = "/activities/search?key=" + resp.data + '&name=' + encodeURIComponent(this.city) + '&city_id=' + this.city_id + '&check_in=' + encodeURIComponent(this.first_date) + '&check_out=' + encodeURIComponent(this.second_date);

        // Prepend selected_language to the route if it's not 'en'
        if (this.selected_language !== 'en') {
            route = `/${this.selected_language}${route}`;
        }

        window.location = route;
    } catch (error) {
        console.error('Error preparing search:', error);
    }
            },
            handle_first_flight_date(event) {
               
         
                this.first_date = event;
            },
            handle_second_flight_date(event) {
                this.second_date = event;
            },
            select_departure_result: function (data) {
                this.departure_search_keyword = data.name;
                this.selected_departure_result_object = data;
            },
            select_destination_result: function (data) {
                this.destination_search_keyword = data.name;
                this.selected_destination_result_object = data;
            },
            addPassenger: function (passenger_type) {
                if (this.passengersCount() > 8) {
                    return;
                }
                this.flight_passengers.forEach((p) => {
                    if (p.id === passenger_type.id) {
                        p.increments += 1;
                        p.passengers.push({
                            id: p.increments,
                        });
                this.passengers_breakup();

                    }
                });
            },
            passengersCount: function () {
                let total = 0;
                this.flight_passengers.forEach((p) => {
                    total += p.passengers.length;
                });
    
                return total;
            },
            removePassenger: function (passenger_type_id, passenger = null) {
                this.flight_passengers.forEach((pt) => {
                    if (pt.id === passenger_type_id) {
                        if (passenger_type_id == 1 && pt.passengers.length == 1) {
                            return;
                        }
                        if (passenger) {
                            pt.forEach((p, index) => {
                                if (p.id === passenger.id) {
                                    p.splice(index, 1);
                                    this.passengers_breakup();

                                }
                            });
                        } else {
                            pt.passengers.splice(0, 1);
                            this.passengers_breakup();

                        }
                    }
                });
            },
            toggle_passengers_panel: function () {
                this.passenger_panel_visible = !this.passenger_panel_visible;
            },
        },
        async mounted() {
            this.selected_language = language_app.selected_language;
            let input__date = document.querySelectorAll(".drp-selected-start, .drp-selected-end");

            if (this.initial_search_data) {
          //  console.log('Initial Search Data:', this.initial_search_data);
            this.paxRooms = JSON.parse(this.initial_search_data.paxRooms); // Use paxRooms instead of rooms
           // console.log('Rooms:', this.rooms);
       // console.log('PaxRooms:', this.paxRooms);
       if (this.$refs.date_picker1) {
      this.$refs.date_picker1.attach_date_picker(
        input__date,
        this.initial_search_data.check_in_date,
        this.initial_search_data.check_out_date
      );
    }
            //let resp1 = await axios.get('/hotel/get-hotel-codes', { params: { Name: this.initial_search_data.Name } });
            //this.initial_search_data.hotelCodes = resp1.data;
            //let resp2 = await axios.get('/hotel/get-city-code', { params: { Name: this.initial_search_data.Name } });
            //this.initial_search_data.cityCode = resp2.data;
            let resp = await axios.post('/activity/suggest_location_by_city_id', { city_id: this.initial_search_data.city_id })
            //console.log(resp.data );

            this.search_keyword = resp?.data?.name
            this.selected_result_object = resp.data;
            this.city_name = this.search_keyword;
            this.city = resp.data?.name;
            this.city_id = resp?.data?.t_id
            this.initial_autocomplete_value = resp?.name
            this.$forceUpdate(); // Force an update
        }

        else {
            let first_date = moment().add(7, 'days').format('YYYY-MM-DD')
            let second_date = moment().add(8, 'days').format('YYYY-MM-DD')
            this.$refs.date_picker1?.attach_date_picker(input__date, first_date, second_date);
        }
    
            this.passengers_breakup();
    
        },
        beforeDestroy() {
    window.removeEventListener('resize', this.checkIfMobile); // Clean up the event listener
  },
    
  watch: {
    selected_flight_type: function (val) {
        let departureDate = null;
        let returnDate = null;

        if (this.search_data) {
            departureDate = this.search_data.departure_date;
            returnDate = this.search_data.return_date;
        }

        if (val !== 1) {
            this.$nextTick(() => {
                let input_date = document.querySelectorAll(".drp-selected-start, .drp-selected-end");
                this.$refs.date_picker2.attach_date_picker(
                    input_date,
                    departureDate,
                    returnDate
                );
            });
        }
    }
}

    
    };
    </script>
    
    <style>
    .has_error
    {
        border-color: red !important;
        color: rgba(212, 22, 22, 0.575) !important;
    
    
    }
    .left-aligned-text {
        text-align: left;
        padding-left: 0px; /* Adjust padding as needed */
    }
    
    
    .invalid-feedback
    {
        display: block !important;
    }
    
    
    
    .d-wf0o1 {
        background: rgb(245, 245, 245);
        border-radius: 12px;
        border: 0px solid rgb(245, 245, 245);
        display: flex;
        gap: 4px;
        overflow: hidden;
        padding: 4px;
        width: fit-content;
    }
    .d-qwct0k {
        -webkit-box-align: center;
        align-items: center;
        cursor: pointer;
        margin: auto 0px;
        padding: 4px 16px;
        text-align: center;
        -webkit-tap-highlight-color: transparent;
        background-color: rgb(255, 255, 255);
        border-radius: 8px;
        border: 0px solid rgb(186, 186, 192);
        box-shadow: rgba(0, 0, 0, 0.04) 0px 4px 8px, rgba(0, 0, 0, 0.06) 0px 0px 2px, rgba(0, 0, 0, 0.04) 0px 0px 1px;
        color: rgb(242, 0, 125);
        font-family: "Open Sans", sans-serif;
        font-size: 14px;
        font-weight: 700;
    }
    
    .d-pdx4vb {
        -webkit-box-align: center;
        align-items: center;
        cursor: pointer;
        margin: auto 0px;
        padding: 4px 16px;
        text-align: center;
        -webkit-tap-highlight-color: transparent;
        background-color: rgb(245, 245, 245);
        border-radius: 8px;
        border: 0px solid rgb(186, 186, 192);
        box-shadow: unset;
        color: rgb(60, 60, 60);
        font-family: "Open Sans", sans-serif;
        font-size: 14px;
        font-weight: 400;
    }
    .booking_modal {
  z-index: 1000; /* Ensure it's on top of other elements */
  display: block; /* Ensure it’s displayed */
}
    
    @media (max-width: 576px) {
    
    .booking_modal .booking_box {
        margin-left: -70px !important;
    }
    }
    .hide_me {
            display: none !important;
        }
        #banner .form_box .box_2 .line, .search_form_box .form_box .box_2 .line::after {
        content: none !important;

}

    </style>
    