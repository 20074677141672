<template>

    <div class="hotel_tab_content pt-3 pb-1">
        <div class="booking_modal_after" v-on:click="remove_modal()"></div>
    
                            <div class="box_1">
                                <div class="bg_silver rounded_10 position-relative">
                                    <label class="w-100">
                                        <span class="d-block fs_14">Find accommodation in</span>
                                        <input @focus="activateAutocomplete(1)" autocomplete="off" v-model="search_keyword" type="text" id="inp_hotel" :class="{ 'is-invalid': submitted && search_keyword == '' }" placeholder="Where do you want to go?">
                                        <div v-if="submitted && search_keyword == ''" class="invalid-feedback">
                                Location is required
                            </div>
                            <span class="clear_input_ticket" @click="clearLocationInput()">
                                <img src="/img/clear_cross.svg" alt="Clear icon">
                            </span>
                                        <AutocompleteComponent ref="autocomplete_1" :has_initial_value="initial_autocomplete_value"
                                        @autocomplete_result_selected="select_result" :type="'hotel'" :keyword="search_keyword">
                                    </AutocompleteComponent>
                                    </label>
                                </div>
                            </div>
                            <div class="box_2">
                                <div class="bg_silver rounded_10 d-flex position-relative">
                                    <label for="date_range" v-on:click.prevent="show_modal('#date_range')">
                                        <span class="d-block fs_14">Check-in</span>
                                        <input autocomplete="off" :value="first_date" type="button" id="first_date" class="left-aligned-text drp-selected-start" readonly="">
                                    </label>
                                    <span class="line"></span>
                                    <label for="date_range" v-on:click.prevent="show_modal('#date_range')">
                                        <span class="d-block fs_14">Check-out</span>
                                        <input autocomplete="off" :value="second_date" type="button" id="second_date" class="left-aligned-text drp-selected-end" readonly="">
                                    </label>
                                    <input type="text" class="date_range">
                                </div>
                                <div id="date_range" :class="{'booking_modal': !isMobile}"  style="position:absolute;width: max-content; left: 350px;z-index:100">
    
                                <DatePicker :unique_id="unique_id" @first_date_changed="handle_first_date"
                @second_date_changed="handle_second_date" ref="date_picker1">
            </DatePicker>
                            </div>
                        </div>
                            <div class="box_3">
                                <div class="bg_silver rounded_10 d-flex position-relative"
                                v-on:click="show_modal('#booking_modal_1')">
                                    <label>
                                        <span class="d-block fs_14">Guests.</span>
                                        <input readonly="" :value="passengers_to_display" ref="input_box" type="button">
                                    </label>
    
                                    <div id="booking_modal_1" class="booking_modal">
                                        <div class="booking_modal_sm position-absolute w-100 h-100 bg-red" v-on:click="remove_modal()"></div>
                                        <div v-for="(room, roomIndex) in rooms" class="shadow booking_box">
                                            <h5 class="fw-bold mb-3 fs_18">Room {{ roomIndex + 1 }}</h5>
                                            <div class="d-flex justify-content-between mb-3">
                                                <div class="me-3" v-for="passenger_type in passenger_types">
                                                    <p class="mb-0 fw_500">{{ passenger_type.type }}</p>
                                                    <p class="mb-0 fs_12">{{ passenger_type.description
                                                    }}</p>
                                                
                                                <div class="wrap_inc_dec">
                                                    <button class="decrement" v-on:click="removePassenger(passenger_type.id, room, null, 0)">-</button>
                                                    <input type="number" readonly="" class="numberInput" :value="getNumberOfPassengers(room, passenger_type.id)">
                                                    <button class="increment" v-on:click="addPassenger(passenger_type.id, room.id, 0)">+</button>
                                                </div>
                                            </div>
                                            </div>
                                            <div class="wrap_child_box me-3">
       <div v-for="(child, childIndex) in roomChildPassengers(room)" :key="child.id" class="child_box d-flex flex-column gap-3 mb-3">
        <p class="mb-0 fw_500">Child {{ childIndex + 1 }}</p>
      
        <select class="nice_select float-none w-100" @change="change_child_age(child, $event.target.value)">
             <option disabled="" value="">Age at time of travel</option>
             <option v-for="n in 11" :key="n" :value="n">{{ n }} years</option>
          </select>
       </div>
    </div>
    
                                            <div class="d-flex justify-content-end position-absolute bottom-20 right-20 z-3">
                                            <button v-if="roomIndex > 0" v-on:click.prevent="removeRoom(room)" class="me-5 txt_pink fw-bold btn_reset fs_14 c_pointer1">
                                                Remove room
                                            </button>
                                            <button v-if="rooms.length < max_nr_of_rooms" v-on:click.prevent="addRoom" class="ms-4 txt_pink fw-bold btn_reset fs_14 c_pointer1">
                                                Add a room
                                            </button>
                                        </div>
                                            
                                        </div>
                                        
                                    </div>
                                </div>
                                
                            </div>
                        <div class="box_3">
                            <div class="d-flex gap-2">
          <!-- Country Code Section -->
          <div class="input_num_code">
            <div>
              <p class="fs_14 mb-0">Nationality</p>
              <img v-if="selectedItem?.flag?.image" width="20" height="20" :src="selectedItem?.flag?.image" alt="Country Flag">
            </div>
            <div>
              <select class="styled-select txt_blue" id="countryCode" v-model="selectedItem" @change="broadcastNewValue">
                <option class="option-style" v-for="country in countries" :value="country" :key="country.code">
                  {{ country?.name }}
                </option>
              </select>
            </div>
          </div>
      
        </div>
    </div>
                            <button class="d-flex align-items-center justify-content-center" @click="search" type="submit">
                                <img width="24" src="/img/search-icon.svg" alt="">
                                <span class="d-md-none d-inline-block text-white fw-semibold fs_14">Find</span>
                            </button>
    
                        </div>
    </template>
    
    <script>
    
    
    
    
    import AutocompleteComponent from "./AutocompleteComponent.vue";
    import DatePicker from "./DatePicker-new.vue";
    import Nationality from "./Nationality.vue";
    import { required } from "vuelidate/lib/validators";
    
    export default {
        components: { AutocompleteComponent, DatePicker, Nationality },
    
        data: function () {
            return {
                countries: [],
            filteredList: [],
            selectedItem: null,
            phone_number: null,
            phone_number_code: null,
            country_code: null,
            nationalityName: null,
            nationalityCode: null,
            current_nationality: null,
            default_country: null,
                isMobile: false,
                input_elements_test: null,
                first_date: '',
                second_date: '',
                search_keyword: '',
                featured_hotel: '',
                city: '',
                selected_language : '',
                city_id: '',
                city_name: '',
                country: [],
                //cityCode: '',
                max_nr_of_rooms: 5,
                passenger_current_index: 1,
                room_current_index: 1,
                selected_result_object: null,
                hotelCodes: [],
                initial_autocomplete_value: false,
                paxRooms: [{ Adults: 1, Children: 0, ChildrenAges: [] }], // Initialize with default values
                unique_id: "hotel_search_datepicker",
    
                passengers_to_display: '',
                passenger_types: [
                    {
                        id: 1,
                        type: 'Adult',
                        description: '18+',
    
                    },
                    {
                        id: 2,
                        type: 'Child',
                        description: 'under 12',
    
                    }
                ],
                rooms: [{
                    id: 1,
    
                    passengers: [
                        {
                            id: 1,
                            passenger_type_id: 1,
                            passenger_age: 0,
    
    
    
                        }
                    ],
    
                }],
                submitted: false,
            };
        },
    
        validations: {
            search_keyword: { required },
    
        },
        created() {
        this.checkIfMobile();
        window.addEventListener('resize', this.checkIfMobile); // Re-check on window resize
      },
    
        computed: {
            PaxRooms() {
          // Calculate PaxRooms based on the rooms data
          return this.rooms.map(room => {
            const adults = room.passengers.filter(p => p.passenger_type_id === 1).length;
            const children = room.passengers.filter(p => p.passenger_type_id === 2).length;
            const childrenAges = room.passengers
              .filter(p => p.passenger_type_id === 2)
              .map(p => p.passenger_age);
    
            return {
              Adults: adults,
              Children: children,
              ChildrenAges: childrenAges,
            };
          });
        },
        
      },
    
        methods: {
            broadcastNewValue() {
            this.country_code = this.selectedItem?.code;
            this.nationalityName = this.selectedItem?.name;
            this.nationalityCode = this.selectedItem?.code;
    
          },
            checkIfMobile() {
          // You can adjust the width threshold according to your mobile definition
          this.isMobile = window.innerWidth <= 768;
        },
        
            activateAutocomplete: function(autocomplete_index)
            {
                let autocomplete_name = 'autocomplete_' + autocomplete_index;
                this.$refs[autocomplete_name].activateAutocomplete();
    
    
            },
            clearLocationInput: function () {
                this.search_keyword = '';
                this.selected_result_object = null;
            },
            passengers_breakup: function () {
                let adults = 0;
                let children = 0;
    
                let rooms = this.rooms;
    
                for (let c = 0; c < rooms.length; c++) {
                    for (let p = 0; p < rooms[c]['passengers'].length; p++) {
                        if (rooms[c]['passengers'][p]['passenger_type_id'] == 1) {
                            adults += 1;
                        } else if (rooms[c]['passengers'][p]['passenger_type_id'] == 2) {
                            children += 1;
                        }
                    }
                }
                if (children == 0) {
                    this.passengers_to_display = `${adults} Adults`
                } else {
                    this.passengers_to_display = `${adults} Adults, ${children} Child`
                }
    
    
            },
            search: async function () {
                this.submitted = true;
    
                let d = new FormData();
        d.append('city_id', this.city_id)
        d.append('Name', this.city_name)
        d.append('City', this.city)
        d.append('featured_hotel', this.featured_hotel)
        d.append('check_in_date', this.first_date)
        d.append('check_out_date', this.second_date)
        d.append('rooms', JSON.stringify(this.rooms))
        d.append('paxRooms', JSON.stringify(this.paxRooms));
        d.append('hotelCodes', JSON.stringify(this.hotelCodes));
        d.append('nationalityCode', this.nationalityCode)
    
        // Conditionally append trip_id if it has a value
        if (this.trip_id !== null && this.trip_id !== undefined) {
            d.append('trip_id', this.trip_id);
        }
    
    
        try {
            let resp = await axios.post('/hotels/prepare_search', d);
               // console.log(resp.data );
    
            let route = "/hotels/search?key=" + resp.data + '&name=' + encodeURIComponent(this.city) + '&city_id=' + this.city_id + '&check_in=' + encodeURIComponent(this.first_date) + '&check_out=' + encodeURIComponent(this.second_date);
    
            if (this.trip_id !== null && this.trip_id !== undefined) {
                route += "&trip_id=" + this.trip_id;
            }
    
            // Prepend selected_language to the route if it's not 'en'
            if (this.selected_language !== 'en') {
                route = `/${this.selected_language}${route}`;
            }
    
            window.location = route;
        } catch (error) {
            console.error('Error preparing search:', error);
        }
    },
            handle_first_date(event) {
                this.first_date = event
                //console.log('first_date ch:', this.first_date);
            },
            handle_second_date(event) {
                this.second_date = event
               // console.log('second_date ch:', this.second_date);
    
            },
            removeRoom(room) {
                this.rooms = this.rooms.filter(r => {
                    return !(room.id == r.id)
                })
                this.passengers_breakup();
    
            },
            change_child_age: function (child, new_value) {
                if (new_value > -1 && new_value < 18) {
                    child.passenger_age = new_value
                }
            },
            addPassenger(passenger_type_id, room_id, age = 0) {
                this.rooms.forEach(r => {
                    if (r.id == room_id) {
                        if (r.passengers.length > 4) {
                            return;
                        }
                        this.passenger_current_index += 1;
                        r.passengers.push({
                            passenger_type_id,
                            passenger_age: age,
                            id: this.passenger_current_index,
    
                        })
                        this.passengers_breakup();
                    }
                })
            },
            checkForInit() {
                let select_box = document.querySelectorAll(".not_init_yet");
                for (let i = 0; i < select_box.length; i++) {
                    NiceSelect.bind(select_box[i]);
    
                }
                for (let r = 0; r < this.rooms.length; r++) {
    
                    for (let p = 0; p < this.rooms[r].passengers.length; p++) {
                        if (this.rooms[r].passengers[p].nice_select_init == false) {
                            this.rooms[r].passengers[p].nice_select_init = true
                        }
                    }
                }
    
    
            },
            removePassenger(passenger_type_id, room) {
    if (passenger_type_id === 1) {
        // Ensure at least 1 adult remains in the room
        let adultCount = room.passengers.filter(p => p.passenger_type_id === 1).length;
        if (adultCount <= 1) return; // Prevent removing the last adult
    }

    // Find index of the last passenger of the given type in the room
    let indexToRemove = room.passengers.findIndex(p => p.passenger_type_id === passenger_type_id);
    
    // Remove the passenger if found
    if (indexToRemove !== -1) {
        room.passengers.splice(indexToRemove, 1);
    }

    this.passengers_breakup();
},
            addRoom() {
                if (this.rooms.length > this.max_nr_of_rooms) {
                    return;
                }
                this.room_current_index += 1;
                this.rooms.push({
                    id: this.room_current_index,
    
                    passengers: [
                        {
                            id: 1,
                            passenger_type_id: 1,
                            passenger_age: 0,
    
    
    
                        },
                        {
                            id: 1,
                            passenger_type_id: 1,
                            passenger_age: 0,
    
    
                        },
                    ],
    
                })
                this.passengers_breakup();
    
            },
            getNumberOfPassengers(room, passenger_type_id) {
                let count = 0;
                let pax = room.passengers.forEach(p => {
                    if (p.passenger_type_id == passenger_type_id) {
                        count += 1;
    
                    }
                })
                return count;
            },
            roomChildPassengers: function (room) {
                let childs = room.passengers.filter(c => {
                    return c.passenger_type_id == 2;
                })
                return childs;
    
            },
    
            select_result: function (data) {
                this.search_keyword = data?.Name || data?.HotelName;
                this.city_name = this.search_keyword
                this.city_id = data?.city_id || data?.CityId
                this.selected_result_object = data;
                this.featured_hotel = data?.HotelCode;
                this.city = data?.Name || `${data?.CityName}, ${data?.CountryName}`;
    
            },
            select_nationality: function (data) {
                console.log(data);
                this.nationalityCode = data.code;
    
            },
    
             show_modal: function(elem) {
      document.querySelector(elem).classList.add("active");
      document.querySelector('.booking_modal_after').classList.add("active");
    },
    
     remove_modal: function() {
      event.stopPropagation();
      let modal = document.querySelectorAll(".booking_modal");
      let after = document.querySelectorAll(".booking_modal_after");
      modal.forEach(element => {
        element.classList.remove("active");
      });
      after.forEach(after => {
        after.classList.remove("active");
      });
    },
    
        },
        props: {
            initial_search_data: null,
            top: null,
            trip_id: null,
        },
        watch: {
            hotelCodes: {
        handler(newHotelCodes, oldHotelCodes) {
          // This function will be called whenever hotelCodes changes.
          // You can perform actions or computations based on the new value (newHotelCodes) or the old value (oldHotelCodes).
          //console.log('hotelCodes changed:', newHotelCodes);
          
          // Example: You can trigger a function when hotelCodes changes.
          // this.someFunction(newHotelCodes);
        },
        deep: true, // Watch for changes in nested properties of hotelCodes
      },
    
      rooms: {
          handler(newRooms) {
            // Recalculate paxRooms based on the newRooms data
            this.paxRooms = newRooms.map(room => {
              const adults = room.passengers.filter(p => p.passenger_type_id === 1).length;
              const children = room.passengers.filter(p => p.passenger_type_id === 2).length;
              const childrenAges = room.passengers
                .filter(p => p.passenger_type_id === 2)
                .map(p => p.passenger_age);
    
              return {
                Adults: adults,
                Children: children,
                ChildrenAges: childrenAges,
              };
            });
          },
          deep: true, // Watch for nested changes in rooms data
        },
      },
    
    
        async mounted() {
           // console.log(this.first_date);
           // console.log(this.initial_search_data);
    
            this.selected_language = language_app.selected_language;
    
            fetch('/country.json')
          .then(response => response.json())
          .then(data => {
            // Set the countries data
            this.country = data;
          })
          .catch(error => {
            console.error('Error loading countries:', error);
          });
    
    
            if (this.trip_id) {
        this.trip_id = this.trip_id;
        }
    /*     if (this.current_nationality) {
        this.nationalityCode = this.current_nationality;
        }
     */
     
            // Check if initial_search_data is defined and contains hotelCodes
        if (this.initial_search_data && this.initial_search_data.hotelCodes) {
          // Assign the hotelCodes to localHotelCodes
          this.HotelCodes = this.initial_search_data.hotelCodes;
        }
              
        let input__date = document.querySelectorAll(".drp-selected-start, .drp-selected-end");
        // console.log('Input Date Elements:', input__date);
    // Convert NodeList to array (for older browsers or to be safe)
    input__date = Array.from(input__date).filter(el => el && el.nodeType === 1);
            if (this.initial_search_data) {
              //  console.log('Initial Search Data:', this.initial_search_data);
                this.nationalityCode = this.initial_search_data.nationalityCode;
                this.rooms = JSON.parse(this.initial_search_data.rooms);
                this.paxRooms = JSON.parse(this.initial_search_data.paxRooms); // Use paxRooms instead of rooms
               // console.log('Rooms:', this.rooms);
           // console.log('PaxRooms:', this.paxRooms);
           if (this.$refs.date_picker1) {
          this.$refs.date_picker1.attach_date_picker(
            input__date,
            this.initial_search_data.check_in_date,
            this.initial_search_data.check_out_date
          );
        }
                //let resp1 = await axios.get('/hotel/get-hotel-codes', { params: { Name: this.initial_search_data.Name } });
                //this.initial_search_data.hotelCodes = resp1.data;
                //let resp2 = await axios.get('/hotel/get-city-code', { params: { Name: this.initial_search_data.Name } });
                //this.initial_search_data.cityCode = resp2.data;
                let resp = await axios.post('/hotel/suggest_location_by_city_id', { city_id: this.initial_search_data.city_id })
                //console.log(resp.data );
    
                this.search_keyword = resp?.data?.Name || resp?.data?.name || resp?.data?.HotelName
                this.selected_result_object = resp.data;
                this.city_name = this.search_keyword;
                this.city = resp.data?.Name || `${resp.data?.CityName}, ${resp.data?.CountryName}`;
                this.featured_hotel = resp?.data?.HotelCode || '';
                this.city_id = resp?.data?.city_id || resp?.data?.code || resp?.data?.CityId
                this.initial_autocomplete_value = resp?.data?.Name || resp?.data?.name || resp?.data?.HotelName
                this.$forceUpdate(); // Force an update
            }
    
            else {
                let first_date = moment().add(7, 'days').format('YYYY-MM-DD')
                let second_date = moment().add(8, 'days').format('YYYY-MM-DD')
                this.$refs.date_picker1?.attach_date_picker(input__date, first_date, second_date);
            }
    
            try {
            let response = await axios.post('/getCountries');
            let nationality = await axios.post('/getNationality');
            this.current_nationality = nationality.data.code;
            this.default_country = this.current_nationality;
            this.countries = response.data;
            this.filteredList = [...this.countries];
      
            let default_country = this.filteredList.find(
              (c) => c.code === this.initial_search_data.nationalityCode
            );
      
            this.selectedItem = default_country || this.filteredList[0];
            this.country_code = this.selectedItem?.code;
            this.nationalityName = this.selectedItem?.name;
            this.nationalityCode = this.selectedItem?.code;
    
          } catch (error) {
            console.error('Error fetching countries:', error);
          }
    
            this.passengers_breakup();
           
        },
        beforeDestroy() {
        window.removeEventListener('resize', this.checkIfMobile); // Clean up the event listener
      },
    };
    </script>
    
    <style>
    
    .input_num_code {
        height: fit-content;
        width: 100%;
    }
    .option-style {
      font-size: 14px;
      padding: 10px;
    }
    .styled-select {
      width: 100%;
      padding: 0px;
      font-size: 16px;
      background-color: #f1f1f1;
      border: 1px solid #ccc;
      border-radius: 4px;
    }
    
    .styled-select:focus {
      border-color: #ff3366;
      outline: none;
    
    }
    .left-aligned-text {
            text-align: left;
            padding-left: 0px; /* Adjust padding as needed */
        }
    </style>
    