<template>
    <div>
      <!-- Wrapper div for the autocomplete items -->
      <div v-show="isVisible" class="autocomplete-items">
        
        <!-- Iterate over the flat list of results (cities, hotels, airports) -->
        <div v-for="(r, index) in results" :key="index" v-on:click="selectResult(r)">
          
          <!-- Icon for cities -->
          <template translate="no" v-if="r.type === 'city'">
            <span class="icon">
              <i class="fas fa-map-marker-alt me-2"></i>
            </span>
            {{ r.Name }}
          </template>

                    <!-- Icon for Activities -->
                    <template translate="no" v-if="r.type === 'activities'">
            <span class="icon">
              <i class="fas fa-map-marker-alt me-2"></i>
            </span>
            {{ r.name }}
          </template>
  
          <!-- Icon for hotels -->
          <template translate="no" v-if="r.type === 'hotel'">
            <span class="icon">
              <img width="20" src="/img/building-icon.png" alt="Hotel Icon" />
            </span>
            {{ r.HotelName }}
            <p class="d-flex align-items-center fs_12 mb-0 text-secondary"><img src="/img/location-icon.svg" width="8" alt="" class="mb-1 me-1">{{ r.CityName }}, {{ r.CountryName }} </p>
          </template>
  
          <!-- Icon for airports -->
          <template translate="no" v-if="r.type === 'airport'">
            <span class="icon">
              <img width="20" src="/img/plane-icon.png" alt="plane-icon" />
            </span>
            {{ r.name }} ({{ r.iata }}), {{ r.city }}
          </template>
  
          <input type="hidden" :value="r.type === 'hotel' ? r.HotelName : r.Name">
          
        </div>
      </div>
    </div>
  </template>
  





<script>
export default {
    data: function () {
        return {
            inputValue: this.keyword || '',
            autocompleteActive: false,
            results: [],
            cancelSource: null,
            isVisible: false,
            lastResult: null,
            firstSearch: true,
            lastReceivedKeyword: "",
            idlePeriod: 100, // ms
            autocomplete_func: null,
            associated_input_element: null,
            mobileViewSearchText: "",
            current_placeholder: "",
        };
    },
    props: {
        keyword: "",
        type: null,
        has_initial_value: null,
        current_value: "",
        input_element: null,
    },
    watch: {
        keyword(newVal) {
            this.inputValue = newVal;
        },
        mobileViewSearchText: async function (newKeyword, oldKeyword) {
            if (
                newKeyword.length < 2 ||
                (this.type !== "internal" &&
                    newKeyword == this.lastResult?.Name || this.lastResult?.name || this.lastResult?.HotelName)
            ) {
                this.isVisible = false;
                //    this.autocompleteActive = false;
                return;
            }

            if (this.firstSearch) {
                if (this.has_initial_value) {
                    this.firstSearch = false;
                    return;
                }
            }

            if (this.current_value == newKeyword) {
                this.isVisible = false;
                this.autocompleteActive = false;
                return;
            }

            await this.performAutocomplete(newKeyword);
        },
        keyword: async function (newKeyword, oldKeyword) {
            if (!newKeyword) {
                this.isVisible = false;
                this.autocompleteActive = false;
                return;
            }
            if (
                newKeyword.length < 2 ||
                (this.type !== "internal" &&
                newKeyword == this.lastResult?.Name || this.lastResult?.name || this.lastResult?.HotelName)
                ) {
                this.isVisible = false;
                //   this.autocompleteActive = false;
                return;
            }

            if (this.firstSearch) {
                if (this.has_initial_value) {
                    this.firstSearch = false;
                    return;
                }
            }

            if (this.current_value == newKeyword) {
                this.isVisible = false;
            //    this.autocompleteActive = false;
                return;
            }

            await this.performAutocomplete(newKeyword);
        },
    },

    methods: {
        setInitialValue(value) {
            this.inputValue = value;
            this.$emit('autocomplete_result_selected', { Name: value });
        },
        performAutocomplete: async function (keyword) {
            if (this.autocomplete_func) {
                clearTimeout(this.autocomplete_func);
            }

            this.autocomplete_func = setTimeout(() => {
                this.fetchData(keyword);
            }, this.idlePeriod);
        },

        fetchData: async function (keyword) {
    let url = "";

    // Determine the endpoint based on the `type` prop
    if (this.type == "hotel") {
        url = "/hotel/suggest_locations";
    } else if (this.type == "airport") {
        url = "/flight/suggest_locations";
    } else if (this.type == "activities") {
        url = "/autocompleteActivity_2";
    } else {
        url = "/suggest_locations";
    }

    // Make the request
    this.cancelSource = axios.CancelToken.source();
    const resp = await axios
        .get(url + "?keyword=" + keyword, {
            cancelToken: this.cancelSource.token,
        })
        .catch(function (thrown) {
            if (axios.isCancel(thrown)) {
                return;
            }
            // handle other errors
        });

    // Flatten results into a single array
    let allResults = [];

    // Add cities with type field
    if (resp && resp.data.cities && resp.data.cities.length) {
        allResults = allResults.concat(
            resp.data.cities.map(city => ({
                ...city,
                type: 'city'
            }))
        );
    }

    // Add hotels with type field
    if (resp && resp.data.hotels && resp.data.hotels.length) {
        allResults = allResults.concat(
            resp.data.hotels.map(hotel => ({
                ...hotel,
                type: 'hotel'
            }))
        );
    }

        // Add activities with type field
        if (resp && resp.data.activities && resp.data.activities.length) {
        allResults = allResults.concat(
            resp.data.activities.map(activity => ({
                ...activity,
                type: 'activities'
            }))
        );
    }

    // Add airports with type field
    if (resp && resp.data.airports && resp.data.airports.length) {
        allResults = allResults.concat(
            resp.data.airports.map(airport => ({
                ...airport,
                type: 'airport'
            }))
        );
    }

    // Set results and make the dropdown visible
    this.results = allResults;
    this.isVisible = this.results.length > 0;
},

        cancelRequest: function () {
            this.cancelSource.cancel();
        },

        selectResult: function (result) {
            this.lastResult = result;
            this.autocompleteActive = false;
            this.isVisible = false;
            this.$emit("autocomplete_result_selected", result);

        },
    },
    mounted() {
        if (this.type == "hotel") {
            this.current_placeholder = "Where are you going?";
        } else if (this.type == "airport") {
            this.current_placeholder = "Enter Airport Name";
        } else if (this.type == "activities") {
            this.current_placeholder = "Where are you going?";
        }

        this.activateAutocomplete = () => {
            this.autocompleteActive = true;

            if (window.innerWidth < 800) {
                this.$nextTick(() => {
                    this.$refs.input_on_mobile?.focus();
                    let elems =
                        document.getElementsByClassName("input_on_mobile");
                    console.log(elems);

                    if (elems.length > 0) {
                        elems?.[0].focus();
                    }
                });
            }
        };
        if (this.has_initial_value) {
            this.lastResult = {
                Name: this.has_initial_value,
            };
        }
        if (this.keyword !== "") {
            this.performAutocomplete(this.keyword);
        }
    },
};
</script>

<style scoped>

</style>