<template>
    <div class="d-flex gap-2">
      <!-- Country Code Section -->
      <div class="input_num_code">
        <div>
          <p class="fs_12 mb-0">Nationality</p>
          <img v-if="selectedItem?.flag?.image" width="20" height="20" :src="selectedItem?.flag?.image" alt="Country Flag">
        </div>
        <div>
          <select id="countryCode" v-model="selectedItem" @change="broadcastNewValue">
            <option v-for="country in countries" :value="country" :key="country.code">
              {{ country?.name }}
            </option>
          </select>
        </div>
      </div>
  
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        countries: [],
        filteredList: [],
        selectedItem: null,
        phone_number: null,
        phone_number_code: null,
        country_code: null,
        nationalityName: null,
        nationalityCode: null,
      };
    },
    props: {
      current_nationality: null,
      default_country: null,
    },
    methods: {
      broadcastNewValue() {
        this.country_code = this.selectedItem?.code;
        this.nationalityName = this.selectedItem?.name;
        this.nationalityCode = this.selectedItem?.code;
    this.$emit('nationality_updated', this.nationalityName);
     this.$emit('nationality_code_updated', this.current_nationality);
     this.$emit('country_code_updated', this.country_code);
     this.$emit('nationality_result_selected', this.selectedItem);
     this.$emit('default_country', this.default_country);
     this.$emit('current_nationality', this.current_nationality);

      },
    },
    async mounted() {
      try {
        let response = await axios.post('/getCountries');
        let nationality = await axios.post('/getNationality');
        this.current_nationality = nationality.data.code;
        this.default_country = this.current_nationality;
        this.countries = response.data;
        this.filteredList = [...this.countries];
  
        let default_country = this.filteredList.find(
          (c) => c.code === nationality.data.code
        );
  
        this.selectedItem = default_country || this.filteredList[0];
        this.country_code = this.selectedItem?.code;
        this.nationalityName = this.selectedItem?.name;
        this.nationalityCode = this.selectedItem?.code;

      this.$emit('nationality_updated', this.nationalityName);
     this.$emit('nationality_code_updated', this.current_nationality);
     this.$emit('country_code_updated', this.country_code);
     this.$emit('nationality_result_selected', this.selectedItem);
     this.$emit('default_country', this.default_country);
     this.$emit('current_nationality', this.current_nationality);
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    },
  };
  </script>
  
  <style scoped>
  /* Add your scoped styles here */
  </style>
  